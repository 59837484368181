import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import commonStyles from 'modules/new-applications/css/common.css';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import useReviewEscalations from 'modules/new-applications/hooks/useReviewEscalations';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement, useState } from 'react';
import { sendSurvey } from 'utils/askNicely';
import basicSort from 'utils/basicSort';
import { setChurnZeroAttributesWithoutRedux } from 'utils/churnZero';

type VALID_REVIEW_DECISIONS =
  | 'accepted'
  | 'declined'
  | 'deescalated'
  | 'escalated'
  | 'reviewed';

interface IActionDetailsContent {
  askNicelyAction: string;
  mixpanelEvent: string;
  mixpanelOutcome: string;
  reviewActionLabel: ({ application, review }) => string;
  reviewButtonText: string;
  reviewDecision: VALID_REVIEW_DECISIONS;
  reviewNotesLabel: string;
  reviewNotesPlaceholder: string;
  reviewNotesErrorLabel?: string;
}

interface IActionDetails {
  decline: IActionDetailsContent;
  deescalate: IActionDetailsContent;
  escalate: IActionDetailsContent;
  review: IActionDetailsContent;
}

const ACTION_DETAILS: IActionDetails = {
  decline: {
    askNicelyAction: 'Review - Declined',
    mixpanelEvent: 'Review Declined',
    mixpanelOutcome: 'Declined',
    reviewActionLabel: ({ application }) =>
      `Team reason for decline of ${application.formattedBusinessName}'s account`,
    reviewButtonText: 'Send decline',
    reviewDecision: 'declined',
    reviewNotesLabel: 'Decline notes',
    reviewNotesPlaceholder: 'Notes shared with your team (optional)',
    reviewNotesErrorLabel: 'Please enter notes for declining',
  },
  deescalate: {
    askNicelyAction: 'Review - De-escalated',
    mixpanelEvent: 'Review De-escalated',
    mixpanelOutcome: 'De-escalated',
    reviewActionLabel: ({ application, review }) => {
      const lowerLevel = review.level - 1;
      const escalatedReview = application.getEscalatedReviewOnLevel(lowerLevel);

      if (escalatedReview) {
        return `You are de-escalating this application back to ${escalatedReview.reviewerName}.`;
      }

      return `You are de-escalating this application to a L${lowerLevel} approver.`;
    },
    reviewButtonText: 'De-escalate',
    reviewDecision: 'deescalated',
    reviewNotesLabel: 'Reason for de-escalation here',
    reviewNotesPlaceholder: 'Reason for de-escalation here',
    reviewNotesErrorLabel: 'Please enter notes for de-escalation',
  },
  escalate: {
    askNicelyAction: 'Review - Escalated',
    mixpanelEvent: 'Review Escalated',
    mixpanelOutcome: 'Escalated',
    reviewActionLabel: () => 'Please select the approver you are escalating to',
    reviewButtonText: 'Escalate',
    reviewDecision: 'escalated',
    reviewNotesLabel: 'Reason for escalation here',
    reviewNotesPlaceholder: 'Reason for escalation here',
    reviewNotesErrorLabel: 'Please enter notes for escalation',
  },
  review: {
    askNicelyAction: 'Application Reviewed',
    mixpanelEvent: 'Application reviewed',
    mixpanelOutcome: 'Reviewed',
    reviewActionLabel: () =>
      'When ready, submit this application as reviewed with applicable notes.',
    reviewButtonText: 'Review',
    reviewDecision: 'reviewed',
    reviewNotesLabel: 'Reviewer notes',
    reviewNotesPlaceholder: 'Reviewer notes',
    reviewNotesErrorLabel: 'Please enter notes for review',
  },
};

interface IEscalateFormProps {
  action: 'decline' | 'escalate';
  application: { [key: string]: any };
  currentUser: { [key: string]: any };
  index: number;
  onCancel: () => void;
  onFetchApplicationRecord: (callback: () => void) => void;
  review: { [key: string]: any };
  escalations: any;
  formattedUserEscalation: any;
  escalationId: string;
}

interface ISubmissionParams {
  application: { [key: string]: any };
  currentUser: { [key: string]: any };
  decision: VALID_REVIEW_DECISIONS;
  onSuccessCallback?: () => void;
  review: { [key: string]: any };
  reviewNotes: string | null;
  selectedUserEscalationId: string | null;
  setHasError: (hasError: boolean) => void;
  escalationId: string;
}

function useSelectedEscalationState({ escalations, taggedEscalationId }) {
  const [selectedUserEscalationId, setselectedUserEscalationId] =
    useState(taggedEscalationId);

  const selectedEscalation =
    escalations.find(
      (escalation) => escalation.value === selectedUserEscalationId
    ) || {};

  return {
    selectedUserEscalationId,
    selectedEscalation,
    setselectedUserEscalationId,
  };
}

const handleEscalation = (params: ISubmissionParams): void => {
  const {
    currentUser,
    review,
    reviewNotes,
    selectedUserEscalationId,
    onSuccessCallback,
  } = params;

  const { escalateReview } = useReviewEscalations({
    reviewId: review.id,
    currentUser,
  });

  escalateReview({
    notes: reviewNotes,
    selectedUserEscalationId,
    onSuccessCallback,
  });
};

const handleDeescalation = (params: ISubmissionParams): void => {
  const { currentUser, review, reviewNotes, escalationId, onSuccessCallback } =
    params;

  const { deescalateReview } = useReviewEscalations({
    reviewId: review.id,
    currentUser,
  });

  deescalateReview({ notes: reviewNotes, escalationId, onSuccessCallback });
};

const onSubmitReview = (params: ISubmissionParams): void => {
  const {
    application,
    currentUser,
    decision,
    onSuccessCallback,
    review,
    reviewNotes,
    escalationId,
  } = params;
  if (['declined', 'reviewed'].includes(decision)) {
    const attributes = {
      decision,
      notes: reviewNotes,
      tradeAccountLimit: 0,
    };

    review.save({
      application,
      attributes,
      currentUser,
      onSuccessCallback,
    });

    return;
  }

  if (decision === 'escalated') {
    handleEscalation(params);

    return;
  }

  if (escalationId) {
    handleDeescalation(params);
  }
};

const GenericApproverForm = ({
  action,
  application,
  currentUser,
  onCancel,
  onFetchApplicationRecord,
  review,
  escalations,
  escalationId,
}: IEscalateFormProps): ReactElement => {
  const { isLoading, setIsLoading } = useIsLoadingState();
  const [reviewNotes, setReviewNotes] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hasNoApprover, setHasNoApprover] = useState(false);
  const {
    selectedUserEscalationId,
    selectedEscalation,
    setselectedUserEscalationId,
  } = useSelectedEscalationState({
    escalations: escalations,
    taggedEscalationId: null,
  });
  const actionDetails = ACTION_DETAILS[action];
  const isEscalate = action === 'escalate';

  const onChangeReviewNotes = (event) => {
    setReviewNotes(get(event, 'target.value'));
  };

  const onSuccessCallback = () => {
    sendSurvey({
      access_token: currentUser.accessToken,
      application_id: null,
      consumer_id: null,
      consumer_name: null,
      email: currentUser.email,
      event_flow: actionDetails.askNicelyAction,
      name: currentUser.fullName,
      supplier: true,
      supplier_id: get(currentUser, 'currentEntity.id'),
      supplier_name: get(currentUser, 'currentEntity.companyName'),
      websitebutton: application.websitebuttonApplication,
    });

    mixpanel.track(actionDetails.mixpanelEvent, {
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Outcome: actionDetails.mixpanelOutcome,
      distinct_id: currentUser.id,
    });

    setChurnZeroAttributesWithoutRedux(currentUser);
    onFetchApplicationRecord(() => {
      setIsLoading(false);
      onCancel();
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const noSelectedEscalation =
      isEscalate && !Object.keys(selectedEscalation).length;
    if (!reviewNotes || noSelectedEscalation) {
      if (noSelectedEscalation) {
        setHasNoApprover(true);
      }
      setHasError(true);
      return;
    }
    setHasError(false);
    setHasNoApprover(false);
    setIsLoading(true);

    onSubmitReview({
      application,
      currentUser,
      decision: actionDetails.reviewDecision,
      onSuccessCallback,
      review,
      reviewNotes,
      selectedUserEscalationId,
      setHasError,
      escalationId,
    });
  };

  const onSelectUserEscalation = (_, value) => {
    const selectedValue = (value || {}).value;

    setselectedUserEscalationId(selectedValue);
    setHasNoApprover(false);
  };

  const approverMessage = 'Please select an approver';
  const approverErrorMessage = hasNoApprover
    ? review.level === 0
      ? approverMessage + '/reviewer'
      : approverMessage
    : undefined;

  const sortedEscalation = basicSort(escalations, 'label');

  const labeledEscalations = sortedEscalation.map((escalation) => {
    const prependedRegex = /^(L\d \-)|(R \-)/;
    if (!prependedRegex.test(escalation.label)) {
      escalation.label =
        escalation.level === 0
          ? `R - ${escalation.label}`
          : `L${escalation.level} - ${escalation.label}`;
    }
    return escalation;
  });

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <form onSubmit={onSubmit}>
        <div className={styles.current_approver_label}>
          {actionDetails.reviewActionLabel({ application, review })}
        </div>
        {isEscalate && (
          <BorderedAutocomplete
            options={labeledEscalations}
            value={selectedEscalation}
            withBottomMargin={false}
            onChange={onSelectUserEscalation}
            style={{ width: '50%', marginBottom: '10px' }}
            textFieldProps={{
              name: 'taggedUserEscalationId',
              error: hasNoApprover,
              helperText: approverErrorMessage,
            }}
          />
        )}
        <BorderedTextArea
          label={actionDetails.reviewNotesLabel}
          placeholder={actionDetails.reviewNotesPlaceholder}
          value={reviewNotes}
          onChange={onChangeReviewNotes}
          style={{ width: '70%' }}
          error={hasError}
          helperText={hasError && actionDetails.reviewNotesErrorLabel}
        />
        <div className={commonStyles.buttons}>
          <Button
            loading={isLoading}
            type="submit"
            text={actionDetails.reviewButtonText}
            style={{ marginRight: 60 }}
          />
          <Button grey text="Cancel" handleClick={onCancel} />
        </div>
      </form>
    </GridContent>
  );
};

export default GenericApproverForm;
